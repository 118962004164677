export const dropIn = {
  hidden: {
    y: "-20vh",
    opacity: 0,
  },
  visible: {
    y: "0",

    opacity: 1,
    transition: {
      duration: 1,
      type: "spring",
      bounce: 0.3,
    },
    transitionEnd: {
      // temp workaround to fix trailing opacity and transform
      opacity: 1,
      y: 0,
    },
  },
  exit: {
    y: "20vh",
    opacity: 0,
    transition: {
      duration: 1,
      type: "spring",
      bounce: 0.3,
    },
  },
};

export const scaleIn = {
  hidden: {
    opacity: 0,
    scale: 0,
  },
  visible: {
    scale: [1, 1, 2, 2, 1, 1],
    opacity: 1,

    transition: {
      duration: 1,
      type: "spring",
      restSpeed: 0.1,
    },
    transitionEnd: {
      // temp workaround to fix trailing opacity and transform
      opacity: 1,
      y: 0,
    },
  },
  exit: {
    opacity: 0,
    scale: 0,
    transition: {
      duration: 1,
      type: "spring",
      bounce: 0.3,
    },
  },
};

export const fadeInOut = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 1,
    },
  },
  exit: {
    opacity: 0,
  },
};

export const staggerItems = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,

    transition: {
      when: "beforeChildren",
      staggerChildren: 0.3,
    },
  },
};

export const item = {
  hidden: { opacity: 0, y: "-20px", scale: 0.9 },
  show: { opacity: 1, y: "0", scale: 1 },
};

export const staggerItemsFade = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,

    transition: {
      delay: 0.3,
      when: "beforeChildren",
      staggerChildren: 0.3,
    },
  },
};

export const itemFade = {
  hidden: { opacity: 0, y: "50px" },
  show: { opacity: 1, y: "0" },
};
