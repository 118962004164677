import { motion } from "framer-motion";
import { dropIn } from "../../utils/animations";

const Wrapper = ({ children, className }) => {
  return (
    <motion.div
      variants={dropIn}
      initial="hidden"
      animate="visible"
      exit="exit">
      <div className={`wrapper ${className}`}>
        <div className="bg-top"></div>
        <div className="wrapper-inside">
          {children}
        </div>
        <div className="bg-bottom"></div>
      </div>
    </motion.div>
  );
};

export default Wrapper;
